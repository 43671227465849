import supabase from 'supabase'
import { OFFERS_TABLE } from 'constants/supabase.contant'
import { executeQuery } from 'utils/executeQuery'
import moment from 'moment'

const createOffersTable = () => supabase.from(OFFERS_TABLE)

const preparePSocials = (payload, prevSocials) => {
    const p_socials = []

    // Define the list of social types you support
    const socialTypes = ['facebook', 'instagram', 'twitter', 'website']

    socialTypes.forEach((type) => {
        // Find the existing social by type
        const existingSocial = prevSocials.find((x) => x.type === type)

        // Get the URL from the payload for this social type
        const payloadUrl = payload[type]

        // If the payload has a URL for this social type
        if (payloadUrl) {
            // If the social exists and the URL has changed, include it for update
            if (existingSocial && existingSocial.url !== payloadUrl) {
                p_socials.push({
                    id: existingSocial.id, // Include id for updating
                    type: type,
                    url: payloadUrl,
                })
            }
            // If the social does not exist, include it for insertion
            else if (!existingSocial) {
                p_socials.push({
                    type: type,
                    url: payloadUrl,
                })
            }
            // If the social exists and the URL is unchanged, include it to retain it
            else if (existingSocial && existingSocial.url === payloadUrl) {
                p_socials.push({
                    id: existingSocial.id,
                    type: type,
                    url: payloadUrl,
                })
            }
        }
        // If the payload does not have a URL but the social exists, omit it to trigger deletion
        // The backend should handle the deletion of any socials not included in p_socials
    })

    return p_socials
}

export const apiAddDeal = async (payload) => {
    // console.log('payload', payload)
    const { data, error } = await supabase.rpc('insert_offer', {
        p_partner_id: payload.partner_id,
        p_description_text: payload.description_text,
        p_title: payload.title,
        p_expiry: moment(payload.expiry).startOf('day').format('YYYY-MM-DD'),
        p_redeem_code: payload.redeem_code,
        p_redeem_url: payload.redeem_url,
        p_usage: payload.usage,
        p_membership_ids: payload.membership_allocation,
    })
    if (error) {
        console.error('Error adding Deal:', error.message)
        return null
    }
    return { data: data?.offer }
}

export const getAllDealApi = async (payload) => {
    try {
        const dealTable = supabase.from(OFFERS_TABLE)
        const {
            pageSize = 10,
            pageIndex = 1,
            query = '',
            filterColumn = [],
            sort = { key: 'created_at', order: 'desc' },
            filters = [],
        } = payload

        const validPageIndex = pageIndex > 0 ? pageIndex : 1
        const validPageSize = pageSize > 0 ? pageSize : 10
        const from = (validPageIndex - 1) * validPageSize
        const to = from + validPageSize - 1

        const membershipFilter = filters.find(
            (filter) => filter.key === 'membership_id'
        )
        const remainingFilters = membershipFilter
            ? filters.filter((filter) => filter.key !== 'membership_id')
            : filters

        const selectString = `
        *,
        partner:partner_id(*),
        offers_memberships!inner(
          membership_id,
          memberships:membership_id(*)
        ),
        deal_redeems!offer_id(count)
      `

        let queryBuilder = dealTable
            .select(selectString, { count: 'exact' })
            .eq('is_delete', false)

        // Apply membership filter
        if (membershipFilter && membershipFilter.value) {
            const membershipId = membershipFilter.value
            queryBuilder = queryBuilder.eq(
                'offers_memberships.membership_id',
                membershipId
            )
        }

        // Apply remaining filters
        if (Array.isArray(remainingFilters) && remainingFilters.length > 0) {
            remainingFilters.forEach(({ key, value }) => {
                if (value != null) {
                    queryBuilder = queryBuilder.eq(key, value)
                }
            })
        }

        // Apply search query
        if (query && Array.isArray(filterColumn) && filterColumn.length > 0) {
            filterColumn.forEach((column) => {
                if (column === 'partner.name') {
                    queryBuilder = queryBuilder
                        .not('partner', 'is', null)
                        .ilike('partner.name', `%${query}%`)
                } else {
                    queryBuilder = queryBuilder.ilike(column, `%${query}%`)
                }
            })
        }

        // Apply sorting
        if (sort.key && sort.order) {
            const ascending = sort.order.toLowerCase() === 'asc'
            queryBuilder = queryBuilder.order(sort.key, { ascending })
        }

        // Apply pagination
        queryBuilder = queryBuilder.range(from, to)

        // Execute query
        const { data, error, count } = await queryBuilder

        if (error) {
            throw error
        }

        return {
            data,
            count,
            message: 'Deals Data fetched successfully.',
        }
    } catch (error) {
        console.error('Error fetching deals:', error.message || error)
        throw error
    }
}

export const getDealByOfferUUIDApi = async (offerId) => {
    try {
        const dealTable = supabase.from(OFFERS_TABLE)

        const selectString = `
                *,
                partner:partner_id(*),
                memberships:offers_memberships(
                offer_id,
                memberships:memberships(*)
            )`

        let queryBuilder = dealTable
            .select(selectString)
            .eq('id', offerId)
            .single()

        const { data, error, count } = await executeQuery(queryBuilder)

        if (error) {
            throw error
        }

        return {
            data,
            count,
            message: 'Deal Data Fetched Successfully',
        }
    } catch (error) {
        console.error('Error fetching deals:', error.message || error)
        throw error
    }
}

export const editDealByUUIDApi = async (payload) => {
    try {
        const payloadData = {
            p_description_text: payload.description_text,
            p_title: payload.title,
            p_expiry: payload.expiry,
            p_redeem_code: payload.redeem_code,
            p_redeem_url: payload.redeem_url,
            p_membership_ids: payload.membership_allocation,
            p_offer_id: payload.offer_id,
            // p_partner_id: payload.partner_id,
            // expiry: moment(payload.expiry).startOf('day').toDate(),
            // usage: payload.usage,
        }
        const { data, error } = await supabase.rpc(
            'edit_partner_offer',
            payloadData
        )

        if (error) {
            throw error
        }
        return {
            data: { ...data?.offer, partner: { ...data?.partner } },
            message: 'Partner offer updated successfully.',
        }
    } catch (error) {
        console.error('Error updating partner offer:', error.message || error)
        throw error
    }
}

export const getDealsStatsApi = async () => {
    try {
        const { data, error } = await supabase.rpc('get_deals_stats')
        if (error) {
            throw error
        }
        return data
    } catch (error) {
        console.error('Error in getDealStats:', error)
        throw error
    }
}

export const changeOfferStatusApi = async (offerId, status) => {
    const offersTable = createOffersTable()
    const changeStatusOfferQuery = offersTable
        .update({ status })
        .eq('id', offerId)
        .select()
        .single()
    const offerData = await executeQuery(changeStatusOfferQuery)
    return offerData
}

export const deleteOfferApi = async (offerId) => {
    const offerTable = createOffersTable()
    const deleteOfferQuery = offerTable
        .update({ is_delete: true })
        .eq('id', offerId)
        .select()
    const offerData = await executeQuery(deleteOfferQuery)
    return offerData
}
