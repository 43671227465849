import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { PAYSTACK_SUBSCRIPTION_STATUS } from 'constants/constant'
import {
    apiGetAllMembers,
    getMemberDetailsByIdApi,
} from 'services/MembersService'

export const initialMembersTableData = {
    total: 0,
    pageIndex: 1,
    pageSize: 10,
    query: '',
    filterColumns: ['title'],
    sort: {
        order: '',
        key: '',
    },
}

export const initialState = {
    members: [],
    selectedMember: {},
    tableData: initialMembersTableData,
}

export const getMembers = createAsyncThunk(
    'get/all-members',
    async (payload) => {
        const { data, error, message } = await apiGetAllMembers(payload)
        if (error) throw new Error(message)

        return {
            data: data.data || [],
            count: data.total_count,
        }
    }
)

export const getMemberDetails = createAsyncThunk(
    'get/member-details',
    async (payload) => {
        try {
            const response = await getMemberDetailsByIdApi(payload)

            const sortedSubscriptions = response.data.user?.subscriptions?.sort(
                (a, b) => new Date(b.created_at) - new Date(a.created_at)
            )

            const activeSub =
                sortedSubscriptions
                    ?.filter(({ plan }) => plan !== null)
                    .find(
                        ({ status }) =>
                            status === PAYSTACK_SUBSCRIPTION_STATUS.Active
                    ) || null
            return {
                data:
                    {
                        ...response.data?.user,
                        activeSubscription:
                            activeSub !== null
                                ? activeSub
                                : sortedSubscriptions.find(
                                      ({ status }) =>
                                          status ===
                                          PAYSTACK_SUBSCRIPTION_STATUS.Active
                                  ),
                    } || {},
            }
        } catch (err) {
            throw err
        }
    }
)

export const memberSlice = createSlice({
    name: 'members',
    initialState,
    reducers: {
        setMembers: (state, data) => {
            state.members = data
        },
        setTableData: (state, action) => {
            state.tableData = action.payload
        },
        resetTableData: (state) => {
            state.tableData = initialMembersTableData
        },
        updateSelectedMember: (state, action) => {
            state.selectedMember = state.selectedMember
                ? { ...state.selectedMember, ...action.payload }
                : action.payload
        },
    },
    extraReducers: {
        [getMembers.pending]: (state) => {
            state.membersLoading = true
            state.membersError = ''
        },
        [getMembers.fulfilled]: (state, { payload }) => {
            state.membersLoading = false
            state.membersError = ''
            state.members = [...payload?.data]
            state.tableData.total = payload?.count
        },
        [getMembers.rejected]: (state, action) => {
            state.membersLoading = false
            state.membersError = action.payload ?? action.error
        },

        [getMemberDetails.pending]: (state) => {
            state.memberDetailsFetchLoading = true
            state.memberDetailsFetchError = ''
        },
        [getMemberDetails.fulfilled]: (state, { payload }) => {
            state.memberDetailsFetchLoading = false
            state.memberDetailsFetchError = ''
            state.selectedMember = payload?.data
        },
        [getMemberDetails.rejected]: (state, action) => {
            state.memberDetailsFetchLoading = false
            state.memberDetailsFetchError = action.payload ?? action.error
        },
    },
})

export const memberActions = memberSlice.actions

export const memberSelector = (state) => state.member

export default memberSlice.reducer
