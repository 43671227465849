import { USERS_TABLE } from 'constants/supabase.contant'
import supabase from 'supabase'
import { executeQuery } from 'utils/executeQuery'
import ApiService from './ApiService'

const createMembersTable = () => supabase.from(USERS_TABLE)

export const apiAddMember = async (payload) => {
    const membersTable = createMembersTable()
    const addMemberQuery = membersTable.insert(payload).select()
    const memberData = await executeQuery(addMemberQuery)
    return memberData
}

export const apiEditMember = async (memberId, payload) => {
    const membersTable = createMembersTable()
    const editMemberQuery = membersTable
        .update(payload)
        .eq('id', memberId)
        .select()
        .single()
    const memberData = await executeQuery(editMemberQuery)
    return memberData
}

export const apiSuspendMember = async (memberId) => {
    const membersTable = createMembersTable()
    const deleteMemberQuery = membersTable
        .update({ account_status: 'suspended' })
        .eq('id', memberId)
        .select()
    const memberData = await executeQuery(deleteMemberQuery)
    return memberData
}

export const apiDeleteMember = async (memberId) => {
    const membersTable = createMembersTable()
    const deleteMemberQuery = membersTable
        .update({ account_status: 'deleted' })
        .eq('id', memberId)
        .select()
    const memberData = await executeQuery(deleteMemberQuery)
    return memberData
}

export const apiGetMemberById = async (memberId) => {
    const membersTable = createMembersTable()
    const getMemberQuery = membersTable
        .select('*')
        .eq('id', memberId.toString())
        .single()
    const memberData = await executeQuery(getMemberQuery)
    return memberData
}

export const apiGetMemberByAuthId = async (memberId) => {
    const membersTable = createMembersTable()
    const getMemberQuery = membersTable
        .select('*')
        .eq('user_id', memberId.toString())
        .eq('role', 'admin')
        .single()
    const memberData = await executeQuery(getMemberQuery)
    return memberData
}

export const apiGetAllMembers = async (payload) => {
    const {
        pageIndex = 1,
        pageSize = 1000,
        searchQuery = null,
        plan_id = null,
        subscription_status,
        product_id = null,
    } = payload

    const getAllMembersQuery = supabase.rpc('get_filtered_users', {
        search: searchQuery,
        subscription_status: !subscription_status ? null : subscription_status,
        plan_id: !plan_id ? null : plan_id,
        subscription_product_id: !product_id ? null : product_id,
        page_size: pageSize,
        page: pageIndex,
    })

    const getAllUsersData = await executeQuery(getAllMembersQuery)
    return getAllUsersData
}

export const apiUpdateUserPassword = async (userId, newPassword) => {
    const userUpdatePasswordQuery = supabase.auth.admin.updateUserById(userId, {
        password: newPassword,
    })
    return await executeQuery(userUpdatePasswordQuery)
}

export const getMemberDetailsByIdApi = async (memberId) => {
    return ApiService.fetchData({
        url: `admin/member/${memberId}`,
        method: 'get',
    })
}
