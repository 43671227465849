import { combineReducers } from 'redux'
import theme from './theme/themeSlice'
import auth from './auth'
import base from './base'
import locale from './locale/localeSlice'
import generalReducer from './generalSlice'
import memberReducer from './members/memberSlice'
import membershipsReducer from './memberships/membershipSlice'
import couponReducer from './coupons/couponSlice'
import giveawayReducer from './giveaways/giveawaySlice'
import dealReducer from './deals/dealSlice'
import partnerReducer from './partners/partnerSlice'
import billingReducer from './billing/billingSlice'
import dashboardReducer from './dashboard/dashboardSlice'

const rootReducer = (asyncReducers) => (state, action) => {
    const combinedReducer = combineReducers({
        theme,
        auth,
        base,
        locale,
        general: generalReducer,
        member: memberReducer,
        memberships: membershipsReducer,
        coupon: couponReducer,
        giveaway: giveawayReducer,
        deal: dealReducer,
        partner: partnerReducer,
        billing: billingReducer,
        dashboard: dashboardReducer,
        ...asyncReducers,
    })
    return combinedReducer(state, action)
}

export default rootReducer
