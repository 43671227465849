import { MEMBERSHIPS_TABLE } from 'constants/supabase.contant'
import supabase from 'supabase'
import { executeQuery } from 'utils/executeQuery'
import ApiService from './ApiService'

const createMembershipTable = () => supabase.from(MEMBERSHIPS_TABLE)

export const addMembershipApi = async (payload) => {
    return ApiService.fetchData({
        url: '/admin/membership',
        method: 'post',
        data: payload,
    })
}

export const apiGetMemberships = async (payload) => {
    const membershipTable = createMembershipTable()
    const {
        pageSize = 10,
        pageIndex = 1,
        query = '',
        filterColumns,
        sort,
    } = payload

    const filters = payload?.filters || []

    const from = (pageIndex - 1) * pageSize
    const to = from + pageSize - 1

    let membershipQuery = membershipTable
        .select('*', { count: 'exact' })
        .eq('is_delete', false)

    if (sort && sort.key !== '' && sort.key !== null) {
        membershipQuery = membershipQuery.order(sort.key, {
            ascending: sort.order === 'asc' ? true : false,
        })
    }

    if (query) {
        filterColumns.forEach((column) => {
            membershipQuery = membershipQuery.ilike(column, `%${query}%`)
        })
    }

    if (Array.isArray(filters) && filters.length > 0) {
        filters.forEach(({ key, value }) => {
            if (value != null) {
                membershipQuery = membershipQuery.eq(key, value)
            }
        })
    }

    membershipQuery = membershipQuery.range(from, to)

    const result = await executeQuery(membershipQuery)

    if (result.error) {
        throw result.error
    }

    return {
        data: result.data,
        count: result.count,
        message: 'Membership Data Fetched Successfully',
    }
}

export const getMembershipByUUIDApi = async (membershipId) => {
    const membershipTable = createMembershipTable()
    const getMembershipDetailsQuery = membershipTable
        .select('*')
        .eq('id', membershipId)
        .single()
    const membershipDetails = await executeQuery(getMembershipDetailsQuery)
    return membershipDetails
}

export const editMembershipApi = async (membershipId, payload) => {
    return ApiService.fetchData({
        url: `/admin/membership/${membershipId}`,
        method: 'put',
        data: payload,
    })

    // const membershipTable = createMembershipTable()
    // const updateMembershipQuery = membershipTable
    //     .update(payload)
    //     .eq('id', membershipId)
    //     .select()
    //     .single()

    // const membershipData = await executeQuery(updateMembershipQuery)
    // return membershipData
}

export const getAllMembershipsApi = async (payload) => {
    try {
        const membershipTable = createMembershipTable()
        const { pageSize, pageIndex } = payload
        const from = (pageIndex - 1) * pageSize
        const to = from + pageSize - 1
        const getAllMemberships = membershipTable
            .select('*', { count: 'exact' })
            .eq('is_delete', false)
            .order('created_at', { ascending: false })
            .range(from, to)
        const getAllMembershipsData = await executeQuery(getAllMemberships)
        return getAllMembershipsData
    } catch (error) {
        console.log(error)
    }
}

export const changeMembershipStatusApi = async (membershipId, status) => {
    const membershipTable = createMembershipTable()
    const changeMembershipStatusQuery = membershipTable
        .update({ status })
        .eq('id', membershipId)
        .select()
        .single()
    const membershipData = await executeQuery(changeMembershipStatusQuery)
    return membershipData
}

export const deleteMembershipsApi = async (membershipId) => {
    const membershipTable = createMembershipTable()
    const deleteMembershipQuery = membershipTable
        .update({ is_delete: true })
        .eq('id', membershipId)
        .select()
    const membershipData = await executeQuery(deleteMembershipQuery)
    return membershipData
}

export const getAllPlansApi = async () => {
    const membershipTable = createMembershipTable()
    const AllPlansQuery = membershipTable
        .update({ is_delete: false })
        .eq('type', 'recurring')
        .select()
    const planData = await executeQuery(AllPlansQuery)
    return planData
}

export const getMembershipsStatsApi = async () => {
    try {
        const { data, error } = await supabase.rpc('get_membership_stats')
        return { data, error, message: '' }
    } catch (err) {
        return { error: true, message: 'Something went wrong!' }
    }
}
