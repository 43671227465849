import { mimeTypeMap } from 'constants/app.constant'
import { DEFAULT_CURRENCY } from 'constants/constant'
import { format, isValid, parseISO } from 'date-fns'
import dayjs from 'dayjs'
// import pdfToText from 'react-pdftotext'

export function formatTime(isoDateString, fmt = 'MMM dd yyyy, h:mm a') {
    if (!isoDateString) return ''
    const date = parseISO(isoDateString)
    const formattedDate = format(date, fmt)
    return formattedDate
}

export const formatDate = (dateString, dateFormat) => {
    const date = new Date(dateString)
    if (!isValid(date)) {
        console.error('Error parsing date: Invalid date string', dateString)
        return ''
    }
    return format(date, dateFormat)
}

export const modifyDateByDays = (endDate, days) => {
    const date = new Date(endDate)
    date.setDate(date.getDate() + days)
    return date
}

export const parseBooleanVal = (val) => {
    if (val === 'true' || val === true || val === '1' || val === 1) return true
    return false
}

export const dateFormatMilliSeconds = (
    milliseconds,
    dateFormat = 'MMM dd, yyyy'
) => {
    const start = new Date(milliseconds)
    const formattedStartDate = format(start, dateFormat)
    return formattedStartDate
}

export const truncateText = (text, maxLength) => {
    if (!text) return
    if (text.length <= maxLength) {
        return text
    }
    return text.substring(0, maxLength) + '...'
}

export function myTimeZone() {
    return Intl.DateTimeFormat().resolvedOptions().timeZone
}

export const fetchFileFromUrl = async (url, name) => {
    try {
        const response = await fetch(url)
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`)
        }

        const blob = await response.blob()
        const fileExtension = getFileExtension(name)
        const fileType =
            mimeTypeMap[fileExtension] || 'application/octet-stream'
        const file = new File([blob], name, { type: fileType })
        return file
    } catch (error) {
        console.error('Error fetching file from URL:', error)
        throw error
    }
}

export const getFileNameFromUrl = (url = '') => {
    const urlObj = new URL(url, window.location.origin)
    return urlObj.pathname.split('/').pop()
}

export const getFileExtension = (fileName = '') => {
    const parts = fileName.split('.')
    return parts.length > 1 ? parts.pop() : ''
}

export function convertCentsToShiling(cents = 0) {
    return (cents / 100).toFixed(2)
}

export const capitalizeFirstLetter = (string) => {
    if (!string || string === undefined) return ''
    return string.charAt(0).toUpperCase() + string.slice(1)
}

export const capitalizeAllFirstLetters = (string) => {
    if (!string || string === undefined) return ''
    return string.replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
        letter.toUpperCase()
    )
}

export const hasBlankSpaces = (str) => {
    if (!str) return false
    return str.match(/^\s+$/) !== null
}

export const transformBillingGraphData = (data, value) => {
    const transformed = {
        week: {
            series: [{ name: `${value}`, data: [] }],
            timeRange: [],
            total: 0,
        },
        month: {
            series: [{ name: `${value}`, data: [] }],
            timeRange: [],
            total: 0,
        },
        year: {
            series: [{ name: `${value}`, data: [] }],
            timeRange: [],
            total: 0,
        },
    }

    const now = dayjs()

    // Calculate date ranges
    const startOfWeek = now.startOf('week')
    const startOfMonth = now.startOf('month')
    const startOfYear = now.startOf('year')

    // Initialize the arrays for week and month views
    for (let i = 0; i < 7; i++) {
        const day = startOfWeek.add(i, 'day')
        transformed.week.timeRange.push(day.format('YYYY-MM-DD'))
        transformed.week.series[0].data.push(0)
    }

    const daysInMonth = now.daysInMonth()
    for (let i = 1; i <= daysInMonth; i++) {
        const day = startOfMonth.add(i - 1, 'day')
        transformed.month.timeRange.push(day.format('YYYY-MM-DD'))
        transformed.month.series[0].data.push(0)
    }

    const months = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
    ]
    months.forEach((month) => {
        transformed.year.timeRange.push(month)
        transformed.year.series[0].data.push(0)
    })

    data.forEach((item) => {
        const createdDate = dayjs(item.created_at)
        const amount = Number(convertCentsToShiling(item.amount))

        if (createdDate.isSame(startOfWeek, 'week')) {
            const day = createdDate.format('YYYY-MM-DD')
            const dayIndex = transformed.week.timeRange.indexOf(day)
            if (dayIndex !== -1) {
                transformed.week.series[0].data[dayIndex] += amount
                transformed.week.total += amount
            }
        }

        if (createdDate.isSame(startOfMonth, 'month')) {
            const day = createdDate.format('YYYY-MM-DD')
            const dayIndex = transformed.month.timeRange.indexOf(day)
            if (dayIndex !== -1) {
                transformed.month.series[0].data[dayIndex] += amount
                transformed.month.total += amount
            }
        }

        const month = createdDate.format('MMM')
        const monthIndex = transformed.year.timeRange.indexOf(month)
        if (monthIndex !== -1) {
            transformed.year.series[0].data[monthIndex] += amount
            transformed.year.total += amount
        }
    })

    return transformed
}

export const getNameProfile = (
    first,
    last,
    background = null,
    textColor = null
) => {
    if (!first || !last) return ''
    let url = `https://ui-avatars.com/api/?name=${first}+${last}&background=${
        background ? background : 'random'
    }`
    if (textColor) {
        url = `${url}&color=${textColor}`
    }
    return url
}
