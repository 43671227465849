export const DEFAULT_CURRENCY = 'KES '
export const DISCOUNT_TYPE = '%'

export const SUBSCRIPTION_STAUTS_OPTIONS = [
    { value: 'active', label: 'Active' },
    { value: 'non-renewing', label: 'Non Renewing' },
    { value: 'attention', label: 'Attention' },
    { value: 'completed', label: 'Completed' },
    { value: 'inactive', label: 'Cancelled' },
]

export const PAYSTACK_SUBSCRIPTION_STATUS = {
    Active: 'active',
    Non_Renewing: 'non-renewing',
    Attention: 'attention',
    Completed: 'completed',
    Cancelled: 'cancelled',
}

export const statusColor = {
    active: 'bg-green-500',
    expired: 'bg-blue-500',
    cancel: 'bg-gray-500',
    pending: 'bg-yellow-500',
    failed: 'bg-yellow-500',
    success: 'bg-green-500',
}
