//Supabase Tables
export const USERS_TABLE = 'users'
export const MEMBERSHIPS_TABLE = 'memberships'
export const USERS_LOCATIONS_TABLE = 'users_locations'
export const PARTNERS_TABLE = 'partners'
export const PARTNER_SOCIALS_TABLE = 'partner_socials'
export const OFFERS_MEMBERSHIPS_TABLE = 'offers_memberships'
export const LOCATION_TABLE = 'locations'
export const CATEGORIES_TABLE = 'categories'
export const PARTNERS_CATEGORIES_TABLE = 'partners_categories'
export const GIVEAWAYS_TABLE = 'giveaways'
export const OFFERS_TABLE = 'offers'
export const COUPONS_TABLE = 'coupons'
export const TRANSACTIONS_TABLE = 'transaction'

// Supabase Images Folder
export const USERS_IMAGES_FOLDER = 'user_images'
export const BRANDS_IMAGES_FOLDER = 'brand_images'
export const GIVEAWAY_IMAGES_FOLDER = 'giveaway_images'
export const PARTNER_IMAGES_FOLDER = 'partner_images'
export const PARTNER_DOCUMENTS_FOLDER = 'partner_documents'

// Supabase Query Joins
