import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
    getBillingGraphDataApi,
    getBillingHistoryApi,
    getBillingStatsApi,
} from 'services/BillingService'
import { transformBillingGraphData } from 'utils/utils'

export const initialMembersTableData = {
    total: 0,
    pageIndex: 1,
    pageSize: 10,
    query: '',
    filterColumns: ['title'],
    sort: {
        order: '',
        key: '',
    },
}

export const initialState = {
    billing: [],
    billingGraphData: [],
    stats: {
        total_members: 0,
        total_memberships: 0,
        total_revenue: 0,
        total_tickets_sold: 0,
    },
    tableData: initialMembersTableData,
}

export const getBillingHistory = createAsyncThunk(
    'get/get-billing-history',
    async (payload) => {
        const { data, error } = await getBillingHistoryApi(payload)
        if (error) throw new Error('Something went wrong!')
        return {
            data,
        }
    }
)

export const getBillingStats = createAsyncThunk(
    'get/all-billing-stats',
    async () => {
        const { data, error } = await getBillingStatsApi()
        if (error) throw new Error('Something went wrong!')
        return {
            data: data || {},
        }
    }
)

export const getBillingGraphData = createAsyncThunk(
    'get/get-billing-graph-data',
    async () => {
        const { data, error, message, count } = await getBillingGraphDataApi()
        if (error) throw new Error('Something went wrong!')
        return {
            data,
            count,
            message,
        }
    }
)

export const billingSlice = createSlice({
    name: 'billing',
    initialState,
    reducers: {
        setMembers: (state, data) => {
            state.members = data
        },
        setTableData: (state, action) => {
            state.tableData = action.payload
        },
        resetTableData: (state) => {
            state.tableData = initialMembersTableData
        },
    },
    extraReducers: {
        [getBillingStats.pending]: (state) => {
            state.billingStatsLoading = true
            state.billingstatsError = ''
        },
        [getBillingStats.fulfilled]: (state, { payload }) => {
            state.billingStatsLoading = false
            state.billingstatsError = ''
            state.stats = payload.data
        },
        [getBillingStats.rejected]: (state, action) => {
            state.billingStatsLoading = false
            state.billingstatsError = action.payload ?? action.error
        },

        [getBillingGraphData.pending]: (state) => {
            state.billingGraphDataLoading = true
            state.billingGraphDataError = ''
        },
        [getBillingGraphData.fulfilled]: (state, { payload }) => {
            state.billingGraphDataLoading = false
            state.billingGraphDataError = ''
            state.billingGraphData = transformBillingGraphData(
                payload.data,
                'Transactions'
            )
        },
        [getBillingGraphData.rejected]: (state, action) => {
            state.billingGraphDataLoading = false
            state.billingGraphDataError = action.payload ?? action.error
        },

        [getBillingHistory.pending]: (state) => {
            state.billingHistoryLoading = true
            state.billingHistoryError = ''
        },
        [getBillingHistory.fulfilled]: (state, { payload }) => {
            state.billingHistoryLoading = false
            state.billingHistoryError = ''
            state.billingHistory = payload?.data?.data || []
            state.tableData.total = payload?.data?.pagination?.total
        },
        [getBillingHistory.rejected]: (state, action) => {
            state.billingHistoryLoading = false
            state.billingHistoryError = action.payload ?? action.error
        },
    },
})

export const billingActions = billingSlice.actions

export const billingSelector = (state) => state.billing

export default billingSlice.reducer
