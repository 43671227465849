import { executeQuery } from 'utils/executeQuery'

const { TRANSACTIONS_TABLE } = require('constants/supabase.contant')
const { default: supabase } = require('supabase')

const createTransactionTable = () => supabase.from(TRANSACTIONS_TABLE)

export const getBillingStatsApi = async () => {
    try {
        const { data, error } = await supabase.rpc(
            'get_billing_stats_current_month'
        )
        return { data, error, message: 'Billing Stats' }
    } catch (err) {
        throw new Error('Something went wrong!')
    }
}

export const getBillingGraphDataApi = async () => {
    try {
        const transactionTable = createTransactionTable()
        let transactionQuery = transactionTable.select('*')
        const { data, error, message, count } = await executeQuery(
            transactionQuery
        )
        return { data, error, message, count }
    } catch (err) {
        throw new Error('Something went wrong!')
    }
}

export const getBillingHistoryApi = async ({ page, limit }) => {
    try {
        const { data, error } = await supabase.rpc('get_billing_history', {
            params: {
                page,
                limit,
            },
        })
        return { data, error, message: 'Billing History!' }
    } catch (err) {
        throw new Error('Something went wrong!')
    }
}

export const getLeaderBoardUsersApi = async ({ limit = 5, page = 1 }) => {
    const { data, error } = await supabase.rpc(
        'fetch_users_with_transactions',
        { page, pagesize: limit }
    )
    if (error) {
        return []
    }
    return data
}
